.job-title {
  color: black;
  font-weight: bold;
  font-size: 60px;
  padding-bottom: 45px;
}

.job-second-title {
  font-weight: bold;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 25px;
}

.job-list {
  li::marker {
    color: #ba2feb;
  }
}

.perks-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .perk-item {
    height: 220px;
    width: 23%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 0 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .perk-top {
      background-color: rgb(231, 108, 108);
      padding: 18px 15px;
      border-radius: 10px;
      color: #a531ef;
    }
    .perk-bottom {
      font-size: 17px;
      text-align: center;
    }
    &:hover {
      background-color: #ba2feb;
      cursor: pointer;
      color: white;
    }
  }
}

.apply-button {
  border: 1px solid #a531ef;
  padding: 14px 0;
  text-align: center;
  border-radius: 30px;
  color: white;
  background-color: #ba2feb;
  font-size: 19px;
  margin-bottom: 50px;
  &:hover {
    cursor: pointer;
    background-color: #9d16f1;
  }
}

.job-main-points {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  .job-main-point {
    display: flex;
    align-items: center;
    .main-point-left {
      margin-left: 10px;
      margin-right: 30px;
      color: #ba2feb;
    }
    .main-point-right {
      .main-point-right-top {
        font-size: 25px;
        font-weight: bold;
      }
      .main-point-right-bottom {
        padding-top: 5px;
        color: rgba(0, 0, 0, 0.699);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .perks-container {
    .perk-item {
      width: 49%;
    }
  }
}
@media screen and (max-width: 800px) {
  .perks-container {
    .perk-item {
      width: 100%;
    }
  }
}
