.card {
  text-decoration: none;

  .card-body {
    color: black;
    font-weight: bold;
    &:hover {
      color: #8924d5;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.blog-heading {
  color: #8924d5;
}

.blog-description {
  font-size: 18px;
}
