.footer-main {
  background-color: #123d6a;
  margin-top: 30px;
  color: #a9a9a9;
}

.footer-second {
  background-color: #292a2c;
  .bottom-text {
    color: white;
    padding: 25px 0;
    text-align: center;
    a {
      text-decoration: none;
      color: rgb(187, 184, 184);
      &:hover {
        color: #ff0000;
      }
    }
  }
}

.footer-navbar-item {
  margin: 15px;
  margin-right: 0;
  .nav-link-footer {
    color: black;
    font-family: "Dudek";
    font-size: 17px;
    padding: 9px 18px;
    text-decoration: none;
    background-color: #bbbbbb;
    &:hover {
      background-color: #ff0000;
      color: white;
      border-radius: 2px;
    }
  }
  .active-footer {
    background-color: #ff0000;
    color: white !important;
    border-radius: 2px;
  }
}

.nav-footer-items {
  justify-content: flex-end;
}

@media only screen and (max-width: 991px) {
  .left-column {
    text-align: center;
    margin-bottom: 20px;
  }
  .nav-footer-items {
    justify-content: center;
  }
}

.left-column {
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: #ff0000;
    }
  }
}

.footer-links {
  margin-bottom: 30px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}

.footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer-links ul li:first-child {
  padding-top: 0;
}

.footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
}

.footer-links ul a:hover {
  text-decoration: none;
  color: #b624e9;
}
