@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@font-face {
  font-family: Dudek;
  src: url(https://adateck.com/static/media/Dudek-Regular.7afb6dc041cb6aa037c7.otf)
    format("woff2");
}
@font-face {
  font-family: Dudek Bold;
  src: url(https://adateck.com/static/media/Dudek-Bold.f05d4c9ca8f3ee4b42ae.otf)
    format("woff2");
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap");
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
* {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: gray !important;
  font-family: Dudek, Dudek Bold, Open Sans, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
  margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.col-12,
.col-lg-6,
.col-md-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-6 {
    float: left;
  }

  .col-lg-6 {
    width: 50%;
  }
}

.no-padding-left {
  padding-left: 0 !important;
}

.col-12,
.col-lg-6,
.col-md-10 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 992px) {
  .col-md-10 {
    float: left;
  }

  .col-md-10 {
    width: 83.33333333%;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.welcome-meter {
  position: relative;
  z-index: 1;
}

.about-sec-wrapper {
  position: relative;
  text-align: left;
}

img {
  vertical-align: middle;
  border-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.about-sec-wrapper .about-i {
  max-width: 90%;
}

.box-shadow {
  -webkit-box-shadow: 0px 10px 27px 0px rgba(154, 161, 171, 0.18);
  box-shadow: 0px 10px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: block;
  top: 0;
}

.article {
  background: #fff;
  padding: 25px 15px 15px;
  border: 1px solid #00dcd8;
  margin-bottom: 30px;
  text-align: left;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}

.article.special {
  background: #edf6fd;
  border: none;
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 4px;
}

.about-sec-wrapper .article {
  background: #a531ef;
  position: absolute;
  width: 70%;
  display: flex;
  height: max-content;
  top: 350px;
  right: 20%;
  text-align: left;
  margin-bottom: 0;
}

.box-shadow:hover {
  -webkit-box-shadow: 0px 25px 36px 0px rgba(154, 161, 171, 0.24);
  box-shadow: 0px 25px 36px 0px rgba(154, 161, 171, 0.24);
}

.article:hover {
  background-image: linear-gradient(to right, #e431ff 0%, #bd2ed3 100%);
}

.promo-section {
  margin-bottom: 20px;
}

h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h4 {
  font-size: 1.5rem;
}

h4 {
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 1.4;
  font-weight: 500;
}

.d-blue {
  color: #a230ef !important;
}

.who-we-contant h4 {
  margin-bottom: 20px;
  font-size: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.about-sec-wrapper .article img {
  margin-top: 15px;
  margin-right: 15px;
  height: inherit;
}

h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h3 {
  font-size: 1.75rem;
}

h3 {
  font-family: "Poppins", sans-serif;
  color: #a230ef;
  line-height: 1.4;
  font-weight: 500;
}

.special-head {
  color: #a230ef !important;
  padding-left: 40px;
  font-size: 18px;
  position: relative;
}

.special-head:before {
  content: "";
  background: #ce2ee6;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
}

.w-text {
  color: #fff !important;
}

.article__title {
  font-size: 18px;
  margin-top: 8px;
}

.article.special .article__title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #13287e;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  line-height: 1.9;
  font-size: 15px;
}

.g-text {
  color: white !important;
}

.article p {
  color: #888;
}

.article.special p {
  color: #888;
}

.col-md-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.side-feature-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.check-mark-icon {
  margin-right: 16px;
  width: 30px;
  height: 30px;
}

.foot-c-info {
  font-weight: 500;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    transform: translateY(0px);
  }
}
/* These were inline style tags. Uses id+class to override almost everything */
#style-Kojfk.style-Kojfk {
  visibility: visible;
  animation-delay: 0.4s;
  animation-name: fadeInUp;
}

.bold-heading {
  font-weight: bold;
  color: #9926f0;
  font-size: 18px;
}
