.home-bottom-title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

// .divider-home {
//   position: relative;
//   height: 1px;
//   background-color: gray;
// }

.home-card {
  background-color: #f8f8f8;
  width: 100%;
  height: auto;
  border-radius: 13px;
  padding: 30px;
  position: relative;
  transition: transform 0.3s ease-out;
  a {
    color: #444;
    text-decoration: none;
  }
  .card-icon {
    margin: 15px 0;
    width: 70px;
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .card-description {
    font-size: 15px;
    padding-bottom: 10px;
  }
  .card-read-more {
    font-size: 13px;
    text-decoration: underline;
  }
  &:hover {
    background-color: #d9271b;
    cursor: pointer;
    box-shadow: 0 -10px 10px -5px rgba(0, 0, 0, 0.5);

    a {
      color: white;
    }
  }
}

.home-divider-new {
  border-bottom: 0.5px solid #979797;
  padding: 20px 0;
}

.home-main {
  border: 2px solid #123d6a;
  padding: 50px;
  border-radius: 50px;
  position: relative;
}

.home-main::before {
  content: "WELCOME TO OPAL DMCC";
  position: absolute;
  font-family: "Dudek Bold";
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0 12px;
  color: #123d6a;
  font-size: 30px;
}

.home-description {
  text-align: justify;
  padding: 0 40px;
  font-size: 18px;
}

.video-top-text {
  color: #123d6a;
  text-align: center;
  font-family: "Dudek Bold";
  padding: 0 30px;
  font-size: 25px;
  text-transform: uppercase;
}

.products-title {
  color: #123d6a;
  padding: 30px 0;
  text-align: center;
  font-family: "Dudek Bold";
  font-size: 40px;
}

.all-products {
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .product-main {
    width: 22%;
    .product-image {
      width: 100%;
    }
    .product-title {
      text-align: center;
      margin: 10px 0;
      font-family: "Dudek Bold";
      color: #123d6a;
      font-size: 18px;
      padding: 0 25px;
    }
  }
}

.home-left-column {
  padding-right: 10% !important;

  .left-title {
    color: #f4925c;
    position: relative;
    font-size: 45px;
    border-top: 3px solid #f4925c;
    margin-bottom: 25px;
  }
  .left-title::before {
    content: "VISION";
    position: absolute;
    font-family: "Dudek Bold";
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0 25px;
    color: #f4925c;
    font-size: 40px;
  }
  .left-title2 {
    color: #f4925c;
    position: relative;
    font-size: 45px;
    border-top: 3px solid #f4925c;
    margin-bottom: 25px;
  }
  .left-title2::before {
    content: "MISSION";
    position: absolute;
    font-family: "Dudek Bold";
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0 25px;
    color: #f4925c;
    font-size: 40px;
  }
  .left-description {
    font-size: 18px;
    margin-bottom: 50px;
    text-align: justify;
  }
  .left-border {
    border: 1px solid #123d6a;
    margin-top: 5px;
    width: 90%;
  }
}

.right-title {
  padding-top: 15px;
  font-size: 35px;
  font-family: "Dudek Bold";
  text-align: right;
  color: #123d6a;
  padding-right: 28px;
}

@media only screen and (max-width: 1200px) {
  .home-main::before {
    top: -20px;
    left: 35%;
    transform: translateX(-10%);
    background-color: white;
    color: #123d6a;
    font-size: 28px;
  }
  .home-left-column {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 789px) {
  .home-left-column {
    text-align: center;
    padding: 0 20px !important;
    .left-border {
      width: 100%;
    }
    .left-title {
      font-size: 35px;
    }
  }
  .all-products {
    .product-main {
      width: 30%;
    }
  }

  .home-main::before {
    top: -15px;
    left: 35%;
    transform: translateX(-22%);
    background-color: white;
    color: #123d6a;
    font-size: 25px;
  }
  .right-title {
    text-align: center;
    font-size: 30px;
    padding: 0;
    margin-top: 20px;
  }
  .left-description {
    margin-bottom: 10px !important;
  }
}

.product-heading {
  color: #f4925c;
  font-family: "Dudek Bold";
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 30px;
}

.home-images {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.list-items {
  font-size: 18px;
}

@media only screen and (max-width: 553px) {
  .home-main::before {
    top: -12px;
    left: 22%;
    transform: translateX(-1%);
    background-color: white;
    color: #123d6a;
    font-size: 22px;
  }
  .all-products {
    .product-main {
      width: 45%;
    }
  }
  .home-description {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 489px) {
  .home-main::before {
    left: 21%;
    font-size: 18px;
  }
  .all-products {
    .product-main {
      width: 100%;
    }
  }
}
