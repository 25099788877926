.careers-title {
  text-align: center;
  padding: 40px 0px;
  font-size: 50px;
  color: black;
  font-weight: bold;
  .adateck-careers {
    color: #a230ef;
  }
}

.all-jobs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .job-container {
    margin-bottom: 30px;
    width: 32%;
    border: 2px solid black;
    border-radius: 9px;
    padding: 10px 20px;
    color: black;
    text-decoration: none;
    .career-job-title {
      font-size: 25px;
      font-weight: bold;
      padding-bottom: 35px;
    }
    .job-bottom-container {
      display: flex;
      justify-content: space-between;
      .job-category {
        background-color: #ba2feb;
        padding: 4px 13px;
        color: white;
        border-radius: 15px;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: #ba2feb;
      color: white;
      .job-category {
        background-color: white;
        color: black;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .all-jobs {
    .job-container {
      width: 49%;
    }
  }
}
@media screen and (max-width: 800px) {
  .all-jobs {
    .job-container {
      width: 100%;
    }
  }
}
